/* ============
 * Getters for the products module
 * ============
 *
 * The getters that are available on the
 * products module.
 */

// Define Getter type for the getter methods
import { GetterTree } from "vuex";

import type { ProductModuleState } from "./state";
import type { RootState } from '../../index';

type Getter = GetterTree<ProductModuleState, RootState>;

// Define and export getter methods
export const getters: Getter = {
    // Get all products
    allProducts: (state) => {
        return state.products;
    },

    // Get product by ID
    getProductById: (state) => (id: number) => {
        return state.products.find((product) => product.id === id);
    },

    // Get total count of products
    totalProductCount: (state) => {
        return state.pagination.count;
    },

    // Get total number of pages
    totalPages: (state) => {
        return state.pagination.pages;
    },

    // Get products sorted by price in ascending order
    productsSortedByPriceAscending: (state) => {
        return [...state.products].sort((a, b) => a.price - b.price);
    },

// Get products sorted by price in descending order
    productsSortedByPriceDescending: (state) => {
        return [...state.products].sort((a, b) => b.price - a.price);
    }
}

export default getters;