/* ============
 * Getters for the account module
 * ============
 *
 * The getters that are available on the
 * account module.
 */

export const getAddresses = (state: any) => {
    const defaultBilling = state.details.defaultBilling;
    const defaultShipping = state.details.defaultShipping;

    return state.addressBook.map( (address) => {
        return {
            id: address.id,
            label: address.id !== -1 ? address.addr1 : 'New Address'
        };
    } );
}

export const getDrawerOpen = (state: any) => state.drawerOpen;

export default {
    getAddresses,
    getDrawerOpen,
};
