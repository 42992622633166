/* ============
 * State of the account module
 * ============
 *
 * The initial state of the account module.
 */

import {Pagination} from "../../../models/generic.model";

export interface TransactionsState {
    estimates: any[];
    orders: any[];
    invoices: any[];
    order: {};
    pagination: any;
    // add more fields here as needed
}

/** Represents the state of the transactions module */
const state: TransactionsState = {
    estimates: [],
    orders: [],
    invoices: [],

    /** Pagination information for the product list */
    pagination: {
        count: 0,
        pages: 0
    } as Pagination,

    order: {}
};

export default state;