/* ============
 * State of the cart module
 * ============
 *
 * The initial state of the cart module.
 */

import type { CartItem, CheckoutDetails } from "../../../models/cart.model";
import { Address } from "../../../models/address.model";

export interface CartModuleState {
    cartItems: CartItem[];
    checkoutDetails: CheckoutDetails;
    shippingDetails: Address,
    billingDetails: Address
}

/** Represents the state of the product module */
const state: CartModuleState = {
    /** An array of items added to the cart */
    cartItems: [] as CartItem[],

    /** The checkout details for the cart */
    checkoutDetails: {
        orderRef: ''
    } as CheckoutDetails,

    /** The selected shipping details entered by the user **/
    shippingDetails: {} as Address,

    /** The selected billing details entered by the user **/
    billingDetails: {} as Address,
}

export default state;