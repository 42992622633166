/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import { ALL } from './mutation-types';

/* eslint-disable no-param-reassign */
export default {
    [ALL](state: any, categories: any) {
        state.categories = categories;
    },
};