/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import type { TransformedProduct } from "../../../models/product.model";
import type {CartItem} from "../../../models/cart.model";
import type {Rate} from "../../../models/shipping-estimate.model";

import { updateField } from 'vuex-map-fields';

/**
 * Adds an item to the cart.
 *
 * @param {Object} state - The Vuex state object.
 * @param {Object} payload - The payload object.
 * @param {TransformedProduct} payload.product - The product object to be added to the cart.
 * @param {number} payload.quantity - The quantity of the product to be added.
 */
const addItemToCart = (state, {product, quantity}) => {
    const { id, name, price, minimumquantity, itempackage } = product as TransformedProduct;

    // Create a new cart item object
    const cartItem = {
        itemid: id,
        name,
        price,
        minimumquantity,
        quantity: Number(quantity),
        itempackage
    };

    // Push the cart item object to the cartItems array
    state.cartItems.push(cartItem);
}

// @ts-ignore
const addShippingToCart = (state, shippingRate: Rate) => {
    if (state.shipping !== shippingRate) {
        state.shipping = shippingRate;
    }
}

// @ts-ignore
const removeItemFromCart = (state, {itemid}) => {
    const cartItem = state.cartItems.find((item: CartItem) => item.itemid === itemid);
    if (cartItem) {
        state.cartItems.splice(state.cartItems.indexOf(cartItem), 1);
    }
}

// @ts-ignore
const EmptyCart = (state) => {
    state.cartItems = [];
}

// @ts-ignore
const clearShipping = (state) => {
    state.shipping = null;
}

// @ts-ignore
const changeQuantityByCartItem = (state, { cartItem: cartItem, quantity: quantity}) => {
    cartItem.quantity = Number(quantity);
};

// @ts-ignore
const incrementQuantityByCartItem = (state, { cartItem: cartItem, quantity: quantity = 1 }) => {
    cartItem.quantity += Number(quantity);
};

// @ts-ignore
const decrementQuantityByCartItem = (state, { itemid }) => {
    const cartItem = state.cartItems.find((item: CartItem) => item.itemid === itemid);
    if (cartItem) {
        cartItem.quantity--;
        if (cartItem.quantity === 0) {
            state.cartItems.splice(state.cartItems.indexOf(cartItem), 1);
        }
    }
};

const updateShippingAddress = (state, address) => {
    state.shippingDetails = address;
}

const updateBillingAddress = (state, address) => {
    state.billingDetails = address;
}

const setInitialCheckoutData = (state, userData) => {
    state.checkoutDetails.orderRef = '';

    state.checkoutDetails.firstname = userData.firstname;
    state.checkoutDetails.lastname = userData.lastname;
    state.checkoutDetails.phone = userData.phone;
    state.checkoutDetails.email = userData.email;
    state.checkoutDetails.title = userData.title;
}

export default {
    addShippingToCart,
    addItemToCart,
    removeItemFromCart,
    EmptyCart,
    clearShipping,
    changeQuantityByCartItem,
    incrementQuantityByCartItem,
    decrementQuantityByCartItem,
    setInitialCheckoutData,
    updateShippingAddress,
    updateBillingAddress,

    updateField
}