/* ============
 * State of the product module
 * ============
 *
 * The initial state of the product module.
 */

import type { TransformedProduct } from 'models/product.model';
import type { Pagination } from 'models/generic.model';

export interface ProductModuleState {
    products: TransformedProduct[];
    pagination: Pagination;
}

/** Represents the state of the product module */
const state: ProductModuleState = {
    /** An array of transformed products fetched from the API */
    products: [] as TransformedProduct[],

    /** Pagination information for the product list */
    pagination: {
        count: 0,
        pages: 0
    } as Pagination
};

export default state;