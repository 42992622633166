/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

import Vuex,{ Payload }  from 'vuex';
// @ts-ignore
import createLogger from 'vuex/dist/logger';

// Modules
import webstore from './modules/webstore';
import products from './modules/products';
import transactions from './modules/transactions';
import cart from './modules/cart';
import user from './modules/user';
import shipping from './modules/shipping'
import auth from './modules/auth'
import settings from './modules/settings'

import VuexPersistence from 'vuex-persist'
import type { ProductModuleState } from "./modules/products/state";

const debug = process.env.NODE_ENV !== 'production';

export interface RootState {
    products: ProductModuleState
}

export interface State {
    user: any,
    cart: any,
    auth: any,
    settings: any,
    shipping: any
}

const vuexLocal = new VuexPersistence<State>({
    //storage: window.localStorage,
    storage: window.sessionStorage,
    reducer: (state) => ({ user: state.user, cart: state.cart, auth: state.auth, settings: state.settings, shipping: state.shipping }), //only save navigation module
    //filter: (mutation) => mutation.type == 'addNavItem',
});

// @ts-ignore
export default new Vuex.Store({
    /**
     * Assign the modules to the store.
     */
    modules: {
        webstore,
        // @ts-ignore
        products,
        transactions,
        cart,
        user,
        shipping,
        auth,
        settings
    },

    /**
     * If strict mode should be enabled.
     */
    strict: debug,

    /**
     * Plugins used in the store.
     */
    plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin],
});