/* ============
 * Mutation types for the product module
 * ============
 *
 * The mutation types that are available
 * on the product module.
 */

/**
 * Mutation types for the product module.
 * @typedef {string} MutationType
 */

/**
 * Mutation type for finding a product.
 * @const {MutationType}
 */
export const FIND = 'FIND';

/**
 * Mutation type for fetching all products.
 * @const {MutationType}
 */
export const ALL = 'ALL';

/**
 * Mutation type for updating a product.
 * @const {MutationType}
 */
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

/**
 * Mutation type for adding a single product.
 * @const {MutationType}
 */
export const ADD_PRODUCT = 'ADD_PRODUCT';

/**
 * Mutation type for removing a single product.
 * @const {MutationType}
 */
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';

/**
 * Mutation type for adding multiple products.
 * @const {MutationType}
 */
export const ADD_PRODUCTS = 'ADD_PRODUCTS';

/**
 * Mutation type for removing multiple products.
 * @const {MutationType}
 */
export const REMOVE_PRODUCTS = 'REMOVE_PRODUCTS';

/**
 * Object that exports all the mutation types.
 * @type {Object.<MutationType>}
 */
export default {
    FIND,
    ALL,
    UPDATE_PRODUCT,
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    ADD_PRODUCTS,
    REMOVE_PRODUCTS,
};