/* ============
 * Mutations for the shipping module
 * ============
 *
 * The mutations that are available on the
 * shipping module.
 */

import { SET_SHIPPING_RATES } from './mutation-types';
import { Rate } from "../../../models/shipping-estimate.model";

/* eslint-disable no-param-reassign */
export default {
    [SET_SHIPPING_RATES](state: any, rates: Rate[]) {
        if (rates)
            state.shippingRates = rates;
    },

    "clearShippingRates"(state: any) {
        state.shippingRates = [];
    }
};
