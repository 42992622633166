/* ============
 * Mutation types for the shipping module
 * ============
 *
 * The mutation types that are available
 * on the shipping module.
 */

export const SET_SHIPPING_RATES = 'SET_SHIPPING_RATES';

export default {
    SET_SHIPPING_RATES
};