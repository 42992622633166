import BaseProxy from './proxy';

class AuthProxy extends BaseProxy {

    /**
     * The constructor for the AuthProxy.
     *
     * @param {Object} parameters The query parameters.
     */
    constructor(parameters = {}) {
        super('auth', parameters);
    }

    /**
     * Method used to login.
     *
     * @param {String} username The username.
     * @param {String} password The password.
     *
     * @returns {Promise} The result in a promise.
     */
    async login({ email, password }) {
        const data = {
            email,
            password,
        };

        return await this.submit('post', `${this.endpoint}/login`, data);
    }

    /**
     * Method used to activate user
     *
     * @param {string} username The username
     * @param {string} password The password
     * @param {string} apiToken The API token provided by Netsuite.
     *
     * @returns {Promise} The result in a promise.
     */
    async activate({ email, password, apiToken }) {
        const data = {
            email,
            password,
            apiToken
        };

        return await this.submit('post', `${this.endpoint}/activate-account`, data);
    }
}

export default AuthProxy