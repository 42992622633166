/* ============
* Actions for the cart module
* ============
*
* The actions that are available on the cart module.
*/

import type { Product, TransformedProduct } from "../../../models/product.model";
import type {CartItem} from '../../../models/cart.model'
import type {Rate} from '../../../models/shipping-estimate.model'
import store from "../../index";

/**
 * Add an item to the cart
 * @param {Object} state - Vuex state object
 * @param {Function} commit - Vuex mutation function call
 * @param {Object} cart - Object containing the product and the desired quantity to add to the cart
 * @param {Product} cart.product - Product to add to the cart
 * @param {number} cart.quantity - Desired quantity of the product to add to the cart
 */
export const addItemToCart = ({ state, commit }, cart: {product: TransformedProduct, quantity: number}) => {
    /**
     * Cart item that matches the product id
     * @type {CartItem}
     */
    const cartItem: CartItem = state.cartItems.find(
        (item: CartItem) => item.itemid === cart.product.id
    );

    if (cartItem) {
        commit("incrementQuantityByCartItem", { cartItem, quantity: cart.quantity });
        return;
    }

    // clear shipping if a product is added or removed
    clearShipping({state, commit});

    commit("addItemToCart", { product: cart.product, quantity: cart.quantity });
};

/**
 * Add shipping to the cart
 * @param {Object} state - Vuex state object
 * @param {Function} commit - Vuex mutation function call
 * @param {Rate} shippingRate - The shipping rate to be added to the cart
 */
export const addShippingToCart = ({ state, commit}, shippingRate: Rate) => {
    commit("addShippingToCart", shippingRate);
}

/**
 * Change the quantity of the specified cart item.
 *
 * @param {Object} state - The Vuex state object.
 * @param {Object} commit - The Vuex commit object.
 * @param {Object} {item} - An object containing the itemid property.
 * @param {number} itemid - The id of the cart item to change.
 * @param {number} quantity - The new quantity
 */
export const changeQuantityByCartItem = ({ state, commit },  { itemid, quantity }: { itemid: number, quantity: number }) => {
    /**
     * Cart item that matches the product id
     * @type {CartItem}
     */
    const cartItem: CartItem = state.cartItems.find(
        (item: CartItem) => item.itemid === itemid
    );

    if (cartItem) {
        // clear shipping if a product quantity is changed.
        clearShipping({state, commit});

        commit("changeQuantityByCartItem", { cartItem: cartItem, quantity: quantity });
    }
};

/**
 * Increment the quantity of the specified cart item.
 *
 * @param {Object} state - The Vuex state object.
 * @param {Object} commit - The Vuex commit object.
 * @param {Object} {itemid} - An object containing the itemid property.
 * @param {Number} itemid - The id of the cart item to increment.
 */
export const incrementQuantityByCartItem = ({ state, commit },  { itemid }: { itemid: number }) => {
    /**
     * Cart item that matches the product id
     * @type {CartItem}
     */
    const cartItem: CartItem = state.cartItems.find(
        (item: CartItem) => item.itemid === itemid
    );

    if (cartItem) {
        // clear shipping if product quantity is changed
        clearShipping({state, commit});

        commit("incrementQuantityByCartItem", { cartItem: cartItem });
    }
};

/**
 * Decrements the quantity of the specified cart item.
 *
 * @param {Object} state - The Vuex state object.
 * @param {Object} commit - The Vuex commit object.
 * @param {Object} {itemid} - An object containing the itemid property.
 * @param {Number} itemid - The id of the cart item to increment.
 */
export const decrementQuantityByCartItem = ({ state, commit },  { itemid }: { itemid: number }) => {
    /**
     * Cart item that matches the product id
     * @type {CartItem}
     */
    const cartItem: CartItem = state.cartItems.find(
        (item: CartItem) => item.itemid === itemid
    );

    if (cartItem) {
        // clear shipping if a product quantity is changed
        clearShipping({state, commit});

        commit("decrementQuantityByCartItem", { cartItem: cartItem });
    }
};

// @ts-ignore
export const removeItemFromCart = ({ state, commit }, cartItem: CartItem) => {
    // clear shipping if a product is added or removed
    clearShipping({state, commit});

    commit("removeItemFromCart", { itemid: cartItem.itemid });
    /*commit(
        "products/restockInventoryByProduct",
        { pid: cartItem.itemid, quantity: cartItem.quantity },
        { root: true }
    );*/
};

export const EmptyCart = ({ state, commit }) => {
    commit("EmptyCart");
};

export const clearShipping = ({ state, commit }) => {
    commit("clearShipping");
    commit('shipping/clearShippingRates', null, { root: true });
};

export const setInitialCheckoutData = ({ state, commit, dispatch, rootState }) => {
    const userData = rootState.user.details;
    console.log('cart - action - ' + JSON.stringify(userData));
    commit("setInitialCheckoutData", userData);
}

export const updateAddress = ({ state, commit, dispatch, rootState }, { addressType, address }) => {
    if (addressType == 'billing') {
        commit('updateBillingAddress', address)
    } else {
        commit('updateShippingAddress', address)
    }
}


export default {
    addShippingToCart,
    addItemToCart,
    removeItemFromCart,
    EmptyCart,
    clearShipping,
    changeQuantityByCartItem,
    incrementQuantityByCartItem,
    decrementQuantityByCartItem,
    setInitialCheckoutData,
    updateAddress
};