/**
 *
 * https://github.com/michoelchaikin/netsuite-mapreduce-util/blob/master/client/package.json
 * https://github.com/vinikira/netsuite-generic-restlet/blob/master/src/generic-restlet.js
 * https://github.com/18chetanpatel/shipit/blob/master/src/router/index.js
 * https://github.com/gary-archer/oauth.websample.final
 *
 */

/* ============
 * Main File
 * ============
 *
 * Will initialize the application.
 */

import Vue from "vue";

/* ============
 * Plugins
 * ============
 *
 * Import and bootstrap the plugins.
 */

import './plugins/vuex';
import './plugins/axios';
import { router } from './plugins/vue-router';
import './plugins/vuex-router-sync';
import vuetify from "./plugins/vuetify";
import './plugins/register-service-worker';
import './plugins/vue-filters';


/* ============
 * Main App
 * ============
 *
 * Last but not least, we import the main application.
 */
Vue.config.productionTip = false;

import App from './App.vue';
import store from './store';

// add the authentication token to the request header
store.dispatch('auth/check');

/* eslint-disable no-new */
new Vue({
    /**
     * Bind the Vue instance to the HTML.
     */
    el: '#app',

    /**
     * The router.
     */
    router,

    /**
     * The Vuex store.
     */
    store,

    /**
     * Vuetify
     */
    vuetify,

    /**
     * Will render the application.
     *
     * @param {Function} h Will create an element.
     */
    render: (h) => h(App),
}).$mount("#app");
