import BaseProxy from './proxy';

class ProductProxy extends BaseProxy {

    /**
     * The constructor for the ArtistProxy.
     *
     * @param {Object} parameters The query parameters.
     */
    constructor(parameters = {}) {
        super('products', parameters);
    }

    /**
     * Method used to retrieve categories.
     *
     * @returns {Promise} The result in a promise.
     */
    async getProducts(category: any[]) {
        if (category.length > 0) {
            if (category[0] === -1)
                this.setParameter('onlyCustomerItems', 1);
            else
                this.setParameter('categoryId', encodeURIComponent(JSON.stringify(category)));
        }

        return await this.all();
    }
}

export default ProductProxy;