/* ============
 * State of the state module
 * ============
 *
 * The initial state of the state module.
 */

export default {
    authenticated: false,
    loggedOutWithError: false,
    bearerToken: null
};