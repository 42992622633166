/* ============
* Actions for the account module
* ============
*
* The actions that are available on the
* account module.
*/

import UserProxy from "../../../proxies/userProxy";
import * as types from "../user/mutation-types";
import { Address } from "../../../models/address.model";

import  store from'../../index'

// @ts-ignore
export const all = async ({ commit }) => {
    new UserProxy()
        .getConfigInformation()
        .then((response: any) => {
            //commit(types.CONFIG, response.config.userPreferences);
            commit(types.DETAILS, {...response.user, ...response.userDetails});
            commit(types.ADDRESS_BOOK, response.addressBook);

            // set the initial checkout data based on the user profile
            store.dispatch('cart/setInitialCheckoutData');
        })
        .catch((e) => {
            console.log('Request failed...' + e);
        });
};

export const details = async ({ commit }) => {
    new UserProxy()
        .getContactDetails()
        .then((response: any) => {
            console.log('User: details ' + JSON.stringify(response));
            //commit( UserTransformer.fetchCollection(response.results));
            commit(types.DEFAULT_SHIPPING, response.data[0]);
        })
        .catch((e) => {
            console.log('Request failed...' + e);
        });
};

export const billingDetails = async ({ commit }) => {
    new UserProxy()
        .getBillingDetails()
        .then((response: any) => {
            commit(types.DEFAULT_BILLING, response.data[0]);
        })
        .catch((e) => {
            console.log('Request failed...' + e);
        });
};

export const shippingDetails = async ({ commit }) => {
    await new UserProxy()
        .getShippingDetails()
        .then((response: any) => {
            commit(types.DEFAULT_SHIPPING, response.data[0]);
        })
        .catch((e) => {
            console.log('Request failed...' + e);
        })
};

export const insertAddress = ({ commit }) => {
    const address = {} as Address;
    address.id = -1;
    address.country = 'AU';

    commit('insertAddress', address);
}

export const saveAddress = async ({ commit }, address) => {
    await new UserProxy()
        .saveAddress(address)
        .then((response: any) => {
            commit(types.ADDRESS_BOOK, response.addressBook);
        })
        .catch((e) => {
            Promise.reject(e);
        })
}

export default {
    all,
    details,

    billingDetails,
    shippingDetails,

    insertAddress,
    saveAddress,

    toggleDrawer({ commit }) {
        commit(types.TOGGLE_DRAWER);
    },
};