/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Vue from 'vue';
import Axios from 'axios';
import store from '../store';

Axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION;
Axios.defaults.headers.common.Accept = 'application/json';

Axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // @ts-ignore
        if (error.response.status === 401 || error.response.status === 403) {
            return Promise.reject(
                store.dispatch('auth/logout', {isError: true})
            );
        }

        return Promise.reject(error);
    },
);

// Bind Axios to Vue.
// @ts-ignore
Vue.$http = Axios;
Object.defineProperty(Vue.prototype, '$http', {
    get() {
        return Axios;
    },
});