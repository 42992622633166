/* ============
 * State of the account module
 * ============
 *
 * The initial state of the account module.
 */

import type {Address} from '../../../models/address.model'

export default {
    config: {},
    user: {},
    details: {},

    addressBook: [] ,

    drawerOpen: true,
};