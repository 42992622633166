/* ============
 * Getters for the account module
 * ============
 *
 * The getters that are available on the
 * account module.
 */

import {CartItem} from "../../../models/cart.model";
import { getField } from 'vuex-map-fields';


// @ts-ignore
const getCartItems = (state, getters, rootState) => {
    return state.cartItems;
}

// @ts-ignore
const getCartTotalItems = (state, getters, rootState) => {
    return state.cartItems.length;
}

// @ts-ignore
const getShippingCost = (state, getters, rootState) => {
    if (state.shipping)
        return state.shipping.amount_net;
    else
        return 0;
}

// @ts-ignore
const getCartTotal = (state, getters) => {
    return state.cartItems.reduce((total: number, item: CartItem) => {
        return total + item.price * item.quantity;
    }, 0);
}

export default {
    getCartItems,
    getCartTotal,
    getCartTotalItems,
    getShippingCost,

    getField
};