/* ============
 * Getters for the account module
 * ============
 *
 * The getters that are available on the
 * account module.
 */

export const getCategory = (state: any) => (id: number)  => {
    return state.categories.find((item: any) => item.id == id);
}

export const getCategoryBreadcumbs = (state: any) => (id: number) => {
    const categories = [];
    if (id === 0) return categories;

    // get child category
    const category = getCategory(id) as any;
    console.log(category);
    categories.push({
        id: category.id,
        name: category.name
    })

    // find the root
    let parentId = category.parent || 0;
    while (parentId !== 0) {
        const parentCategory = getCategory(parentId);
        categories.push({
            id: parentId,
            name: parentCategory.name
        })

        parentId = category.parent || 0;
    }

    return categories;
}

export default {
    getCategoryBreadcumbs,
    getCategory
};