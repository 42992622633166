/* ============
 * Account Transformer
 * ============
 *
 * The transformer for the account.
 */

import Transformer from './transformer';

export default class WebstoreTransformer extends Transformer {
    /**
     * Method used to transform a fetched account.
     *
     * @param category The fetched account.
     *
     * @returns {Object} The transformed account.
     */
    static fetch(category: { recordType: any; id: any; values: any; }) {
        let child;
        let parent = 0;
        let names = category.values.name.split(':');

        if (names.length > 1) {
            child = names[names.length - 1];
            parent = category.values.parent[0].value * 1;
        } else {
            child = category.values.name;
        }

        return {
            id: category.id*1,
            name: child.trim(),
            parent: parent,
            file: 'category'
        };
    }

    /**
     * Method used to transform a send account.
     *
     * @param account The account to be send.
     *
     * @returns {Object} The transformed account.
     */
    static send(account: { email: any; firstName: any; lastName: any; }) {
        return {
            email: account.email,
            first_name: account.firstName,
            last_name: account.lastName,
        };
    }
}