/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 * https://github.com/drudge/n8n-nodes-netsuite/blob/main/nodes/NetSuite/NetSuite.node.ts
 * https://github.com/shivaprasad17/netsuite-rest-web-services/blob/main/services/authorization/index.js
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '../router';
import store from "../store";

Vue.use(VueRouter);

export const router = new VueRouter({
    routes,
    mode: "history"
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((m) => m.meta.auth) && !store.state.auth.authenticated) {
        /*
         * If the user is not authenticated and visits
         * a page that requires authentication, redirect to the login page
         */
        next({
            path: '/login',
        });
    } else {
        next();
    }
});

// @ts-ignore
Vue.router = router;

export default {
    router
};