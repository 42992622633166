/* ============
 * Mutation types for the auth module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const CHECK = 'CHECK';
export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export default {
    CHECK,
    REGISTER,
    LOGIN,
    LOGOUT,
};