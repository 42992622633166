import Layout from '.././components/Layout.vue'
//import Default from '.././views/default.vue'

export default [
    {
      path: '/',
      component: () => import('../views/Template.vue'),
      name: 'Template',
      redirect: '/dashboard',
      children: [
          {
              path: '/dashboard',
              component: () => import('../views/Dashboard.vue'),
              name: 'Dashboard',
              meta: {
                  auth: true
              }
          },
          {
              path: '/checkout',
              component: () => import('../views/Checkout.vue'),
              name: 'Checkout',
              meta: {
                  auth: true
              }
          },
          {
              path: '/orders',
              component: () => import('../views/MyOrders.vue'),
              name:'MyOrders',
              meta: {
                  auth: true
              }
          },
          {
              path: '/quotes',
              component: () => import('../views/MyQuotes.vue'),
              name:'MyQuotes',
              meta: {
                  auth: true
              }
          },
          {
              path: '/invoices',
              component: () => import('../views/MyInvoices.vue'),
              name:'MyInvoices',
              meta: {
                  auth: true
              }
          },
          {
              path: '/order-online',
              component: () => import('../views/OrderOnline.vue'),
              name:'OrderOnline',
              meta: {
                  auth: true
              }
          },
          {
              path: '/order-complete/:orderid',
              component: () => import('../views/OrderComplete,.vue'),
              name: 'OrderComplete',
              meta: {
                  auth: true
              }
          },
          {
              path: '/view-order/:orderid',
              component: () => import('../views/ViewOrder.vue'),
              name: 'ViewOrder',
              meta: {
                  auth: true
              }
          },
          {
              path: '/view-quote/:quoteid',
              component: () => import('../views/ViewQuote.vue'),
              name: 'ViewQuote',
              meta: {
                  auth: true
              }
          },
          {
              path: '/view-invoice/:invoiceid',
              component: () => import('../views/ViewInvoice.vue'),
              name: 'ViewInvoice',
              meta: {
                  auth: true
              }
          },
          {
              path: '/users',
              component: () => import('../views/Users.vue'),
              name:'Users',
              meta: {
                  auth: true
              }
          },
          {
              path: '/reports',
              component: () => import('../views/Reports.vue'),
              name:'Reports',
              meta: {
                  auth: true
              }
          }
      ]
    },
    {
        path: '/login',
        component: () => import('../components/Login.vue'),
        name: 'Login',
        meta: {
            auth: false
        }
    },
    {
        path: '/activate-login/:token?',
        component: () => import('../components/Activation.vue'),
        name: 'Activation',
        meta: {
            auth: false
        }
    },
    {
        path: '/activate-success',
        component: () => import('../components/ActivationSuccessful.vue'),
        name: 'ActivationSuccess',
        meta: {
            auth: false
        }
    },
    {
        path: '/callback',
        component: () => import('../components/Callback.vue'),
        name: 'Callback',
        meta: {
            auth: false
        }
    }
];
