/* ============
* Actions for the account module
* ============
*
* The actions that are available on the
* account module.
*/

import * as types from './mutation-types';

import { ActionContext } from "vuex";

import TransactionTransformer from "../../../transformers/transactionTransformer";
import TransactionProxy from "../../../proxies/transactionProxy";

import { RootState } from "../../index";
import {TransactionsState} from "./state";
import Vue from "vue";

// @ts-ignore
export const all = async ({ commit }: ActionContext<TransactionsState, RootState>, transactionType: any) => {
    let commitType = types.ALL_ESTIMATES;
    if (transactionType === 'SalesOrd') commitType = types.ALL_SALES_ORDERS;
    if (transactionType === 'CustInvc') commitType = types.ALL_INVOICES;

    try {
        const response = await new TransactionProxy(transactionType).getProducts();
        const transactions = TransactionTransformer.fetchCollection(response.results);
        const pagination = response.pagination;
        commit(commitType, { transactions, pagination });

        return Promise.resolve({ transactions, pagination });
    } catch (error) {
        console.error("Failed to fetch transactions", error);
        return Promise.reject(error);
    }
};

export const fetchRecentTransactions = async ( {commit, state, dispatch}: ActionContext<TransactionsState, RootState>, transactionType: any) => {
/*
    const startDate = Vue.prototype.$moment(payload.startDate, "DD/MM/YYYY").toDate();
    const endDate = Vue.prototype.$moment(payload.endDate, "DD/MM/YYYY").toDate();
    const limit = payload.limit || 10;
    const limit = payload.limit || 10;*/
    let stateType = 'estimates';
    if (transactionType === 'SalesOrd') stateType = 'orders';
    if (transactionType === 'CustInvc') stateType = 'invoices';
    const now = new Date();

    try {
        if (state[stateType].length === 0) {
            await dispatch("all", transactionType);
        }

        const filteredTransactions = state[stateType].filter((transaction) => {
            const [day, month, year] = transaction.trandate.split("/");
            const trandate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
            const daysAgo = (now.getTime() - trandate.getTime()) / (1000 * 60 * 60 * 24);
            return daysAgo <= 30;
        });

        const pagination = {
            page: 1,
            total: filteredTransactions.length,
        };

        return Promise.resolve({filteredTransactions, pagination})
    } catch (error) {
        console.error("Failed to fetch recent transactions", error);
        return Promise.reject(error);
    }
}

export const saveSalesOrder = async ({commit, state, rootState}) => {
    // construct the payload
    let payload = {
        products: {},
        shipping_address: rootState.cart.shippingDetails,
        billing_address: rootState.cart.billingDetails,
        otherrefnum: rootState.cart.checkoutDetails.orderRef,
        memo: rootState.cart.checkoutDetails.memo
    };

    // map the cart into the appropriate payload structure
    payload.products = rootState.cart.cartItems.map((item) => {
        return {
            ...item,
            id: item.itemid
        };
    });

    try {
        const response = await new TransactionProxy().saveOrder(payload);
        return Promise.resolve(response);
        // @todo - remove the cart items and reset the state
        // also need to redirect the user once saved.
    } catch (error) {
        console.error("Failed to save sales order", error);
        return Promise.reject(error);
    }
}

export default {
    all,
    fetchRecentTransactions,
    saveSalesOrder
};