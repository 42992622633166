/* ============
* Actions for the shipping module
* ============
*
* The actions that are available on the
* shipping module.
*/

import * as types from './mutation-types';

import ShippingProxy from "../../../proxies/shippingProxy";

// @ts-ignore
export const calculateShippingCost = async (context) => {
    const { commit, state, rootState } = context;

    // no point recalculating them if you need to
    if (state.shippingRates.length > 0)
    {
        return Promise.resolve(state.shippingRates);
    }

    try {
        const cartItems = rootState.cart.cartItems;
        const shippingAddress = rootState.cart.shippingDetails;

        if (!cartItems && shippingAddress) throw Error('No items or shipping address specified.');

        const { rates } = await new ShippingProxy().calculateShippingCost(shippingAddress, cartItems);
        commit(types.SET_SHIPPING_RATES, rates);

        return Promise.resolve(rates);
    } catch (error) {
        console.error("Request failed: ", error);
        return Promise.reject(error);
    }
};

export default {
    calculateShippingCost,
};