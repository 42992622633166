/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import {ALL, ALL_ESTIMATES, ALL_SALES_ORDERS, ALL_INVOICES} from './mutation-types';

/* eslint-disable no-param-reassign */
export default {
    [ALL_ESTIMATES](state: any, {transactions: transactions, pagination: pagination}) {
        state.estimates = transactions;
        state.pagination = pagination;
    },
    [ALL_SALES_ORDERS](state: any, {transactions: transactions, pagination: pagination}) {
        state.orders = transactions;
        state.pagination = pagination;
    },
    [ALL_INVOICES](state: any, {transactions: transactions, pagination: pagination}) {
        state.invoices = transactions;
        state.pagination = pagination;
    },
};