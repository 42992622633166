import Vue from 'vue';
import axios from 'axios';

class BaseProxy {

    protected endpoint: any;
    protected parameters: any;

    /**
     * The constructor of the BaseProxy.
     *
     * @param {string} endpoint   The endpoint being used.
     * @param {Object} parameters The parameters for the request.
     */
    constructor(endpoint: any, parameters = {}) {
        this.endpoint = endpoint;
        this.parameters = parameters;
    }

    /**
     * Method used to set the query parameters.
     *
     * @param {Object} parameters The given parameters.
     *
     * @returns {BaseProxy} The instance of the proxy.
     */
    setParameters(parameters: { [x: string]: any; }) {
        Object.keys(parameters).forEach((key) => {
            this.parameters[key] = parameters[key];
        });

        return this;
    }

    /**
     * Method used to set a single parameter.
     *
     * @param {string} parameter The given parameter.
     * @param {*} value The value to be set.
     *
     * @returns {BaseProxy} The instance of the proxy.
     */
    setParameter(parameter: string | number, value: any) {
        this.parameters[parameter] = value;

        return this;
    }

    /**
     * Method used to remove all the parameters.
     *
     * @param {Array} parameters The given parameters.
     *
     * @returns {BaseProxy} The instance of the proxy.
     */
    removeParameters(parameters: any[]) {
        parameters.forEach((parameter) => {
            delete this.parameters[parameter];
        });

        return this;
    }

    /**
     * Method used to remove a single parameter.
     *
     * @param {string} parameter The given parameter.
     *
     * @returns {BaseProxy} The instance of the proxy.
     */
    removeParameter(parameter: string | number) {
        delete this.parameters[parameter];

        return this;
    }

    /**
     * The method used to perform an AJAX-request.
     *
     * @param {string}      requestType The request type.
     * @param {string}      url         The URL for the request.
     * @param {Object|null} data        The data to be send with the request.
     *
     * @returns {Promise} The result in a promise.
     */
    async submit(requestType: string, url: string, data = null) {
        // @ts-ignore
        return Vue.$http[requestType](url + this.getParameterString(), data)
            .then((response: { data: unknown; }) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    return Promise.reject(new Error('Unauthorized'));
                } else {
                    if (error.response && error.response.data)
                        return Promise.reject(error.response.data);
                }

                return Promise.reject(new Error(error));
            });
    }

    /**
     * Method used to fetch all items from the API.
     *
     * @returns {Promise} The result in a promise.
     */
    all() {
        return this.submit('get', `/${this.endpoint}`);
    }

    /**
     * Method used to fetch a single item from the API.
     *
     * @param {int} id The given identifier.
     *
     * @returns {Promise} The result in a promise.
     */
    find(id: any) {
        return this.submit('get', `/${this.endpoint}/${id}`);
    }

    /**
     * Method used to create an item.
     *
     * @param {Object} item The given item.
     * @param id The given identifier
     *
     * @returns {Promise} The result in a promise.
     */
    create(item: null | undefined, id?: any) {
        if (id)
            return this.submit('post', `/${this.endpoint}/${id}`, item);
        else
            return this.submit('post', `/${this.endpoint}`, item);
    }

    /**
     * Method used to update an item.
     *
     * @param {int}    id   The given identifier.
     * @param {Object} item The given item.
     *
     * @returns {Promise} The result in a promise.
     */
    update(id: any, item: null | undefined) {
        return this.submit('put', `/${this.endpoint}/${id}`, item);
    }

    /**
     * Method used to destroy an item.
     *
     * @param {int} id The given identifier.
     *
     * @returns {Promise} The result in a promise.
     */
    destroy(id: any) {
        return this.submit('delete', `/${this.endpoint}/${id}`);
    }

    /**
     * Method used to transform a parameters object to a parameters string.
     *
     * @returns {string} The parameter string.
     */
    getParameterString() {
        const keys = Object.keys(this.parameters);

        const parameterStrings = keys
            .filter((key) => !!this.parameters[key])
            .map((key) => `${key}=${this.parameters[key]}`);

        return parameterStrings.length === 0 ? '' : `?${parameterStrings.join('&')}`;
    }
}

export default BaseProxy;