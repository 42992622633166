import BaseProxy from './proxy';

class WebstoreProxy extends BaseProxy {

    /**
     * The constructor for the ArtistProxy.
     *
     * @param {Object} parameters The query parameters.
     */
    constructor(parameters = {}) {
        super('webstore', parameters);
    }

    /**
     * Method used to retrieve categories.
     *
     * @returns {Promise} The result in a promise.
     */
    async getCategories() {
        const data = {};

        return await this.all();
    }
}

export default WebstoreProxy;