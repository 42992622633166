/* ============
 * Account Transformer
 * ============
 *
 * The transformer for the account.
 */

import Transformer from './transformer';

export default class TransactionTransformer extends Transformer {
    /**
     * Method used to transform a fetched account.
     *
     *
     * @returns {Object} The transformed account.
     * @param transaction
     */
    static fetch(transaction: { recordType: any; id: any; values: any}) {
        return {
            recordType: transaction.recordType,
            id: transaction.id,
            trandate: transaction.values.trandate,
            statusref: transaction.values.statusref[0] ? transaction.values.statusref[0].text : '',
            tranid: transaction.values.tranid,
            amount: transaction.values.amount * 1,
            otherrefnum: transaction.values.otherrefnum
        };
    }

    /**
     * Method used to transform a send account.
     *
     * @param account The account to be send.
     *
     * @returns {Object} The transformed account.
     */
    static send(account: { email: any; firstName: any; lastName: any; }) {
        return {
            email: account.email,
            first_name: account.firstName,
            last_name: account.lastName,
        };
    }
}