/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the account module.
 */

export const FIND = 'FIND';
export const ALL = 'ALL';

export const CONFIG = 'ALL';
export const USER = 'USER';
export const DETAILS = 'DETAILS';
export const ADDRESS_BOOK = 'ADDRESS_BOOK';

export const DEFAULT_BILLING = 'DEFAULT_BILLING';
export const DEFAULT_SHIPPING = 'DEFAULT_SHIPPING';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

export default {
    FIND,
    ALL,
    CONFIG,
    USER,
    DETAILS,

    // default billing and shipping address details
    DEFAULT_BILLING,
    DEFAULT_SHIPPING,

    TOGGLE_DRAWER
};