import defaultSettings from '../../config';

const getDefaultSettings = () => {
    return JSON.parse(JSON.stringify(defaultSettings.settings));
};

const settings = {
    state: getDefaultSettings(), // Deep Clone

    getters: {
        navbarDense: (state) => state.navbar.dense,
        navbarLogo: (state) => state.navbar.logo,
        navbarShow: (state) => state.navbar.show,
        fullscreenBtn: (state) => state.fullscreen.btn,
        fullscreenShow: (state) => state.fullscreen.show,
        settingsPanelBtn: (state) => state.settingsPanel.btn,
        settingsPanelShow: (state) => state.settingsPanel.show,
    },

    mutations: {
        SET_SETTINGS: (state, payload) => {
            state.navbar = payload.navbar || state.navbar;
            state.fullscreen = payload.fullscreen || state.fullscreen;
            state.settingsPanel = payload.settingsPanel || state.settingsPanel;
        },
        NAVBAR_DENSE_TOGGLE: (state) => {
            state.navbar.dense = !state.navbar.dense;
        },
        NAVBAR_LOGO_TOGGLE: (state) => {
            state.navbar.logo = !state.navbar.logo;
        },
        NAVBAR_TOGGLE: (state) => {
            state.navbar.show = !state.navbar.show;
        },
        NAVBAR_STATE: (state, payload) => {
            state.navbar.show = payload.state;
        },
        FULLSCREEN_TOGGLE: (state, payload) => {
            state.fullscreen.show = payload.state;
        },
        FULLSCREEN_BTN: (state, payload) => {
            state.fullscreen.btn = payload.state;
        },
        SETTINGS_PANEL_BTN_TOGGLE: (state) => {
            state.settingsPanel.btn = !state.settingsPanel.btn;
        },
        SETTINGS_PANEL_TOGGLE: (state) => {
            state.settingsPanel.show = !state.settingsPanel.show;
        },
        SETTINGS_PANEL_STATE: (state, payload) => {
            state.settingsPanel.show = payload.state;
        },
    },

    actions: {
        NavbarDenseToggle: async (context) => {
            context.commit('NAVBAR_DENSE_TOGGLE');
        },
        NavbarLogoToggle: async (context, payload) => {
            context.commit('NAVBAR_LOGO_TOGGLE', payload);
        },
        NavbarToggle: async (context, payload) => {
            context.commit('NAVBAR_TOGGLE', payload);
        },
        NavbarState: async (context, payload) => {
            context.commit('NAVBAR_STATE', payload);
        },
        FullscreenToggle: async (context, payload) => {
            context.commit('FULLSCREEN_TOGGLE', payload);
        },
        FullscreenBtn: async (context, payload) => {
            context.commit('FULLSCREEN_BTN', payload);
        },
        SettingsPanelBtnToggle: async (context, payload) => {
            context.commit('SETTINGS_PANEL_BTN_TOGGLE', payload);
        },
        SettingsPanelToggle: async (context, payload) => {
            context.commit('SETTINGS_PANEL_TOGGLE', payload);
        },
        SettingsPanelState: async (context, payload) => {
            context.commit('SETTINGS_PANEL_STATE', payload);
        },
        SettingsPanelDefault: async (context) => {
            context.commit('SET_SETTINGS', getDefaultSettings());
        }
    }

}

export default settings;