/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the account module.
 */

export const FIND = 'FIND';
export const ALL = 'ALL';
export const ALL_SALES_ORDERS = 'ALL_SALES_ORDERS';
export const ALL_ESTIMATES = 'ALL_ESTIMATES';
export const ALL_INVOICES = 'ALL_INVOICES';

export default {
    FIND,
    ALL,
    ALL_SALES_ORDERS,
    ALL_ESTIMATES,
    ALL_INVOICES
};