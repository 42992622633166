const packageJson = require('../package.json');

export default {
    title: packageJson.title,

    /**
     * @type {String} '0.0.1'
     * @description Application version.
     * After the release of the new version, it is reset to the default settings.
     */
    version: packageJson.version,

    /**
     * @type {Object}
     * @description Default application settings.
     */
    settings: {
        locale: 'en',
        navbar: {
            show: true,
            dense: false,
            logo: false,
        },
        fullscreen: {
            show: false,
            btn: true,
        },
        settingsPanel: {
            show: false,
            btn: true,
        },
    }
}