/* ============
 * Mutations for the product module
 * ============
 *
 * The mutations that are available on the
 * product module.
 */

import { ADD_PRODUCT, ADD_PRODUCTS, ALL, REMOVE_PRODUCT, REMOVE_PRODUCTS, UPDATE_PRODUCT } from "./mutation-types";

import type { ProductModuleState } from './state';
import { MutationTree } from "vuex";

import type { TransformedProduct } from "../../../models/product.model";
import type { Pagination } from "../../../models/generic.model";

/** Represents the mutations for the product module */
const mutations: MutationTree<ProductModuleState> = {

    /**
     * Mutation to update the products and pagination state
     *
     * @param {ProductModuleState} state - The Vuex state object
     * @param {Object} payload - The payload object containing products and pagination data
     * @param {TransformedProduct[]} payload.products - The transformed products data
     * @param {Pagination} payload.pagination - The pagination data
     */
    [ALL](state: ProductModuleState, payload: { products: TransformedProduct[], pagination: Pagination }) {
        state.products = payload.products;
        state.pagination = payload.pagination;
    },

    /**
     * Mutation to update a specific product in the products state
     *
     * @param {ProductModuleState} state - The Vuex state object
     * @param {TransformedProduct} updatedProduct - The updated product data
     */
    [UPDATE_PRODUCT](state: ProductModuleState, updatedProduct: TransformedProduct) {
        const index = state.products.findIndex(product => product.id === updatedProduct.id);
        if (index !== -1) {
            state.products.splice(index, 1, updatedProduct);
        }
    },

    /**
     * Mutation to add a new product to the products state
     *
     * @param {ProductModuleState} state - The Vuex state object
     * @param {TransformedProduct} newProduct - The new product data
     */
    [ADD_PRODUCT](state: ProductModuleState, newProduct: TransformedProduct) {
        state.products.push(newProduct);
    },

    /**
     * Mutation to remove a product from the products state
     *
     * @param {ProductModuleState} state - The Vuex state object
     * @param {string} productId - The ID of the product to be removed
     */
    [REMOVE_PRODUCT](state: ProductModuleState, productId: number) {
        const index = state.products.findIndex(product => product.id === productId);
        if (index !== -1) {
            state.products.splice(index, 1);
        }
    },

    /**
     * Mutation to add products to the state's products array.
     * @param {ProductModuleState} state - The state of the product module.
     * @param {TransformedProduct[]} products - The array of products to be added.
     */
    [ADD_PRODUCTS](state, products) {
        products.forEach(product => {
            // Check if product id already exists in state
            const existingProduct = state.products.find(p => p.id === product.id);
            if (!existingProduct) {
                state.products.push(product);
            }
        });
    },

    /**
     * Mutation to remove products from the state's products array.
     * @param {ProductModuleState} state - The state of the product module.
     * @param {TransformedProduct[]} products - The array of products to be removed.
     */
    [REMOVE_PRODUCTS](state, products) {
        state.products = state.products.filter(product => !products.find(p => p.id === product.id));
    }
}

export default mutations;

