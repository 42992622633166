/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * auth module.
 */

import Vue from "vue";
import {
    CHECK,
    LOGIN,
    LOGOUT,
} from './mutation-types';

/* eslint-disable no-param-reassign */
export default {
    [CHECK](state) {
        if (state.authenticated) {
            const token = state.bearerToken;

            // @ts-ignore
            Vue.$http.defaults.headers.common.Authorization = `Bearer ${token}`;
        }
    },

    [LOGIN](state, token) {
        state.authenticated = true;
        state.bearerToken = token;

        // @ts-ignore
        Vue.$http.defaults.headers.common.Authorization = `Bearer ${token}`;
    },

    [LOGOUT](state, isError = false) {
        state.authenticated = false;
        state.loggedOutWithError = isError;
        state.bearerToken = false;

        // @ts-ignore
        Vue.$http.defaults.headers.common.Authorization = '';
    },
}