/* ============
* Actions for the account module
* ============
*
* The actions that are available on the
* account module.
*/

import * as types from './mutation-types';

import WebstoreTransformer from "../../../transformers/webstoreTransformer";
import WebstoreProxy from "../../../proxies/webstoreProxy";

// solution from user "FurkanO" (https://stackoverflow.com/a/40732240/5135171)
const createDataTree = (dataset: any[]) => {
    let hashTable = Object.create(null)
    dataset.forEach( aData => hashTable[aData.id] = { ...aData, children : [] } )
    let dataTree: any[] = []
    dataset.forEach( aData => {
        if( aData.parent ) {
            hashTable[aData.parent].children.push(hashTable[aData.id])
        }
        else dataTree.push(hashTable[aData.id])
    } )

    for(let i = 0; i < dataTree.length; i++) {
        dataTree[i].children = [{
            id: dataTree[i].id,
            name: "All " + dataTree[i].name
        }, ...dataTree[i].children];
    }

    return dataTree
};

// @ts-ignore
export const find = async ({ commit }) => {
    /*
     * Normally you would use a proxy to fetch the account:
     *
     * new Proxy()
     *  .find()
     *  .then((response) => {
     *    commit(types.FIND, Transformer.fetch(response));
     *  })
     *  .catch(() => {
     *    console.log('Request failed...');
     *  });
     */

    await new WebstoreProxy()
        .getCategories()
        .then((response: any) => {
            let categories = WebstoreTransformer.fetchCollection(response);
            const dataTree = createDataTree(categories);

            commit(types.ALL, dataTree);
        })
        .catch(() => {
            console.log('Request failed...');
        });

/*    const account = {
        first_name: 'John',
        last_name: 'Doe',
        email: 'jonh@doe.com',
    };

    commit(types.FIND, Transformer.fetch(account));*/
};

export default {
    find,
};