import BaseProxy from './proxy';

class TransactionProxy extends BaseProxy {

    /**
     * The constructor for the ArtistProxy.
     *
     * @param transactionType
     * @param {Object} parameters The query parameters.
     */
    constructor(transactionType: any = 'SalesOrder', parameters = {}) {
        super('transactions/' + transactionType, parameters);
    }

    /**
     * Method used to retrieve categories.
     *
     * @returns {Promise} The result in a promise.
     */
    async getProducts() {
        return await this.all();
    }

    /**
     * Method used to view the transaction
     *
     * @returns {Promise} The returned transaction object.
     */
    async viewTransaction(transactionId: number) {
        return await this.find(transactionId);
    }

    async downloadTransaction(transactionId: number) {
        return await this.find(transactionId + '/download');
    }

    /**
     * Method to save the transaction given a payload
     *
     * @param payload - the products to be saved into the transaction order
     */
    async saveOrder(payload: any) {
        return await this.create(payload);
    }

}

export default TransactionProxy;