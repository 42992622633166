import BaseProxy from './proxy';

class UserProxy extends BaseProxy {

    /**
     * The constructor for the ArtistProxy.
     *
     * @param {Object} parameters The query parameters.
     */
    constructor(parameters = {}) {
        super('user', parameters);
    }

    /**
     * Method used to retrieve categories.
     *
     * @returns {Promise} The result in a promise.
     */
    async getConfigInformation() {
        return await this.all();
    }

    async getContactDetails() {
        return await this.find('details');
    }

    async getBillingDetails() {
        return await this.find('billing');
    }

    async getShippingDetails() {
        return await this.find('shipping');
    }

    async saveAddress(address) {
        return await this.create(address, 'address');
    }
}

export default UserProxy;