import Vue from 'vue';

Vue.filter('toCurrency', function (value: any) {
  if (typeof value !== "number") {
    return value;
  }

  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD"
  });
  return formatter.format(value);

})