/* ============
 * State of the shipping module
 * ============
 *
 * The initial state of the shipping module.
 */

import { Rate } from 'models/shipping-estimate.model';

let shippingRates: Rate[] = [];

export default {
    shippingRates,
};