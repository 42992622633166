/* ============
* Actions for the products module
* ============
*
* The actions that are available on the
* products module.
*/

import { ALL } from "./mutation-types";

import { ActionContext } from "vuex";

import ProductTransformer from "../../../transformers/productTransformer";
import ProductProxy from "../../../proxies/productProxy";

import type { Product } from "../../../models/product.model";
import type { Pagination } from "../../../models/generic.model";
import type { ProductModuleState } from "./state";
import type { RootState } from "../../index";

/**
 * Helper function to commit products and pagination data to Vuex store.
 *
 * @param {ActionContext} context - The action context object.
 * @param {Object} results - The results object from the API response.
 * @returns {Promise<void>} - A Promise that resolves when the data is committed to the store.
 */
const commitProductsData = async (context: ActionContext<ProductModuleState, RootState>, results: any): Promise<void> => {
    const { commit } = context;

    const { data }: { data: Product[] } = results;
    const { pagination }: { pagination: Pagination } = results;

    commit(ALL, { products: ProductTransformer.fetchCollection(data), pagination });
};

/**
 * Fetches all products based on a category ID.
 *
 * @async
 * @function all
 * @param {Object} context - The Vuex context object.
 * @param {Object} payload - The payload object containing categoryId.
 * @param {number} payload.categoryId - The ID of the category.
 * @throws {Error} - If the API request fails.
 * @returns {Promise<Object>} - The response object from the API.
 */
export const all = async (context, { categoryId }) => {
    const { commit, getters, rootState } = context;

    const requestPayload: number[] = categoryId === null ? [] : [
        categoryId, ...rootState.webstore.categories
                        .find(category => category.id === categoryId)?.children
                        ?.map(child => child.id) || []];

    let attempts = 0;
    let results = null;

    while ( attempts < 5 ) {
        try {
            // Fetch products from the API using the request payload
            const proxy = new ProductProxy();
            const response = await proxy.getProducts(requestPayload);

            if (response) {
                results = response.results;

                await commitProductsData(context, results);
                break; // Break the loop if successful response received
            }
        } catch (error) {
            console.error("Request failed: ", error);

            //.response && error.response.status !== 408
            if (error) {
                // Stop making requests if error response other than timeout
                return Promise.reject(error);
            }
        }

        attempts++;
    }

    if (results) {
        return Promise.resolve(results);
    } else {
        return Promise.reject(new Error("Maximum number of attempts reached"));
    }
};

/**
 * Action to search for products.
 *
 * @param {Object} context - The Vuex context object.
 * @param {Object} payload - The payload object.
 * @param {string} payload.keywords - The keywords to search for.
 * @throws {Error} - If the API request fails.
 * @returns {Promise<void>} - A Promise that resolves when the action is complete.
 */
export const search = async (context, { keywords }) => {
    const { commit, getters, rootState } = context;

    try {
        const {results} = await new ProductProxy().find('search/' + encodeURIComponent(keywords));

        await commitProductsData(context, results);

        return Promise.resolve(results);
    } catch (error) {
        console.error("Request failed: ", error);
        return Promise.reject(error);
    }
};

export default {
    all,
    search
};