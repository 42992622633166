

/*
 * The entry point of the application.
 */
export default {

    name: 'App',

}

