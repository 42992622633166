import BaseProxy from './proxy';

import type {Address} from "../models/address.model";
import type { Cart, CartItem } from "../models/cart.model";
import { Package } from "../models/product.model";

class ShippingProxy extends BaseProxy {

    /**
     * The constructor for the ArtistProxy.
     *
     * @param {Object} parameters The query parameters.
     */
    constructor(parameters = {}) {
        super('shipping', parameters);
    }

    /**
     * Method used to retrieve categories.
     *
     * @returns {Promise} The result in a promise.
     */
    async calculateShippingCost(address: Address, cartItems: CartItem[]) {
        const itemPackages = [] as Package[];
        cartItems.forEach((item) => {
            itemPackages.push(item.itempackage)
        })

        const payload = {
            "ship_to_address" : address,
            "packages": itemPackages
        }

        return await this.submit('post', `/${this.endpoint}/calculateShippingCost`, payload);
    }
}

export default ShippingProxy;